var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    {
      ref: "layout",
      attrs: { column: "", "justify-center": "", "fill-height": "" },
    },
    [
      _c(
        "v-flex",
        { staticClass: "mission-instructions", attrs: { "d-flex": "" } },
        [_c("ResizableText", { attrs: { message: _vm.username } })],
        1
      ),
      _c(
        "SlideOpacityTransition",
        [
          _c("OptionsList", {
            attrs: { show: _vm.showOptions, options: _vm.multipleChoice },
            scopedSlots: _vm._u([
              {
                key: "option",
                fn: function (ref) {
                  var option = ref.option
                  var index = ref.index
                  return [
                    _c(
                      "ChoiceBtn",
                      {
                        attrs: {
                          inactive: !_vm.isOptionActive,
                          correct: _vm.isOptionCorrect(index),
                          wrong: _vm.isOptionWrong(index),
                        },
                        on: {
                          click: function ($event) {
                            return _vm.submit(index, option)
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(option) +
                            " " +
                            _vm._s(_vm.numOfResponses(option)) +
                            " "
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      !_vm.isPresenterUser
        ? _c(
            "MissionStatus",
            {
              attrs: {
                hideInstant: "",
                correct: _vm.missionSuccess && _vm.isMissionCompleted,
                wrong: _vm.missionFail && _vm.isMissionCompleted,
                show: _vm.showStatus,
              },
            },
            [
              !_vm.isHost
                ? _c("span", [_vm._v(_vm._s(_vm.statusText))])
                : _c("span", [
                    _vm._v(" Responses: " + _vm._s(_vm.totalResponses) + " "),
                  ]),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }