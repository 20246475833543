<template>
  <v-layout column justify-center fill-height ref="layout">
    <!-- Mission question -->
    <v-flex d-flex class="mission-instructions">
      <ResizableText :message="username" />
    </v-flex>

    <!-- Options -->
    <SlideOpacityTransition>
      <OptionsList :show="showOptions" :options="multipleChoice">
        <template #option="{ option, index }">
          <ChoiceBtn
            :inactive="!isOptionActive"
            :correct="isOptionCorrect(index)"
            :wrong="isOptionWrong(index)"
            @click="submit(index, option)"
          >
            {{ option }} {{ numOfResponses(option) }}
          </ChoiceBtn>
        </template>
      </OptionsList>
    </SlideOpacityTransition>

    <!-- Status -->
    <MissionStatus
      v-if="!isPresenterUser"
      hideInstant
      :correct="missionSuccess && isMissionCompleted"
      :wrong="missionFail && isMissionCompleted"
      :show="showStatus"
    >
      <span v-if="!isHost">{{ statusText }}</span>
      <span v-else> Responses: {{ totalResponses }} </span>
    </MissionStatus>
  </v-layout>
</template>

<script>
import { mapGetters } from "vuex"
import { GameMixin } from "@/mixins"
import ResizableText from "@/components/GroupTeams/Common/Games/ResizableText.vue"
import Ellipsis from "@/components/GroupTeams/Common/Games/GameCardParts/Ellipsis"
import MissionStatus from "@/components/GroupTeams/Common/Games/GameCardParts/MissionStatus"
import ChoiceBtn from "@/components/GroupTeams/Common/Games/GameCardParts/ChoiceBtn"
import OptionsList from "@/components/GroupTeams/Common/Games/GameCardParts/OptionsList"
import SlideOpacityTransition from "@/components/GroupTeams/Common/Games/GameCardParts/SlideFadeTransition.vue"
import User from "@shared/User"

export default {
  name: "TwoTruthsAsset",
  components: {
    SlideOpacityTransition,
    OptionsList,
    Ellipsis,
    MissionStatus,
    ChoiceBtn,
    ResizableText
  },
  mixins: [GameMixin],
  props: {
    mode: String
  },
  data() {
    return {
      index: 0,
      twoTruthsInput: null
    }
  },
  computed: {
    ...mapGetters("auth", ["isHost", "user"]),
    ...mapGetters([
      "missionFail",
      "missionSuccess",
      "getCurrentMission",
      "missionPlaysArray",
      "onlineUsersArray",
      "missions",
      "gameStatus",
      "gameID",
      "plays",
      "missionCompletedPlays"
    ]),
    ...mapGetters("GameUsers", ["users"]),
    isPresenterUser() {
      return User.isPresenter(this.user)
    },
    missionAnswers() {
      return this.missionPlaysArray.reduce((acc, item) => {
        if (item.userID === this.user.id) {
          acc[item.twoTruthsID] = item.answer[0]
        }
        return acc
      }, {})
    },
    totalResponses() {
      const currentUserID = this.currentUserID
      return this.missionPlaysArray.filter(
        play => play.twoTruthsID === currentUserID
      ).length
    },
    isMissionCompleted() {
      const { currentUserID } = this
      return this.missionCompletedPlays.some(
        play => play.twoTruthsID === currentUserID
      )
    },
    play() {
      const { currentUserID, getCurrentMission: mission, plays } = this
      const missionID = mission.twoTruthsMissionID

      return plays.find(
        play => play.userID === currentUserID && play.missionID === missionID
      )
    },
    correctAnswerIdx() {
      const { correct, answer } = this.play || {}
      return (answer || []).findIndex(string => string === (correct || [])[2])
    },
    multipleChoice() {
      const { answer } = this.play || {}
      return answer || []
    },
    currentUserID() {
      return this.gameStatus.twoTruthsID
    },
    username() {
      return this.users[this.currentUserID]?.firstname // + " " + currentUser.lastname
    },
    statusText() {
      const {
        missionFail,
        isScribe,
        isMissionCompleted,
        isHost,
        multipleChoice,
        correctAnswerIdx
      } = this
      if (!missionFail && isMissionCompleted) {
        return
      }

      if (missionFail && isMissionCompleted) {
        return
      }

      if (this.isWaiting) {
        return "Waiting on Scribe..."
      }

      if (!isHost && isScribe && !isMissionCompleted) {
        return "Choose the Lie"
      }

      if (isHost) {
        return "Answer: " + multipleChoice[correctAnswerIdx]
      }

      return ""
    },
    isWaiting() {
      return !this.isHost && !this.isScribe && !this.isMissionCompleted
    },
    showStatus() {
      return (
        this.mode === "play" || this.mode === "huddle" || this.mode == "social"
      )
    },
    showOptions() {
      return this.mode === "social"
    },
    isScribe() {
      return this.$store.getters.isScribe
    },
    isOptionActive() {
      return this.isScribe && !this.isMissionCompleted
    }
  },
  methods: {
    numOfResponses(answer) {
      if (this.missionPlaysArray) {
        const nOfSameAnswers = this.missionPlaysArray.reduce((acc, item) => {
          if (
            item.twoTruthsID === this.currentUserID &&
            item.correct === answer
          ) {
            ++acc
          }
          return acc
        }, 0)

        if (nOfSameAnswers) {
          // show the number of results
          if (
            this.isHost ||
            this.isMissionCompleted ||
            this.user.id === this.currentUserID
          ) {
            return `(${nOfSameAnswers})`
          }
        }
      }
    },
    isCorrect(idx) {
      return parseInt(this.correctAnswerIdx) === idx
    },
    // if is in the state
    isGiven(idx) {
      return (
        this.missionAnswers[this.gameStatus.twoTruthsID] ===
        this.multipleChoice[idx]
      )
    },
    // show if it's in the state or the mission is over
    isOptionCorrect(idx) {
      return (
        ((this.isGiven(idx) || this.isHost) && this.isCorrect(idx)) ||
        (this.isMissionCompleted && this.isCorrect(idx))
      )
    },
    isOptionWrong(idx) {
      return this.isGiven(idx) && !this.isCorrect(idx)
    },
    getButtonClass(idx) {
      // console.log("GET BUTTON CLASS INDEX", idx)
      return {
        active: this.isOptionActive,
        wrong: this.isOptionWrong(idx),
        correct: this.isOptionCorrect(idx)
      }
    },
    async submit(index, string) {
      if (!this.isScribe || this.isMissionCompleted)
        return console.log("No rights for submit")
      this.twoTruthsInput = string
      await this.checkAnswer()
    }
  }
}
</script>

<style scoped lang="scss">
.mission-instructions {
  padding: 10px;
  line-height: 1.2em;
}
</style>
